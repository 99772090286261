import React from "react";

import { Card, Col, Container } from "react-bootstrap";
import { EncodeSignatures, validSignatories } from "../other/helpers";
import moment from "moment";

function WithUseCases({ workApproval, approver }) {
	return (
		<RemarkContainer
			workApproval={workApproval}
			approver={approver}
		/>
	);
}

class RemarkContainer extends React.Component {

	constructor() {
		super();
		this.state = {
			remarks: []
		};
	}

	componentDidMount() {
		this.setState({
			remarks: EncodeSignatures(
				this.props.workApproval.approvers.filter(
					(approver) => ["APPROVED", "RECEIVED", "REJECTED"].includes(approver.status) && approver?.remarks
				),
				(value, _) => value,
				validSignatories
			).signatureInputs.filter(
				(signatory) => signatory.approvers.length > 0
			)
		});
	}

	format(signatory, signatoryKey) {
		return (
			<div className="pb-10" key={signatoryKey}>
				<b>{signatory.title}: </b>
				<div className="row px-2">
					{
						signatory.approvers.map((approver, approverKey) => {
							return (
								<div key={approverKey} className="col-12 py-2">
									<div
										className="row py-2 g-1 border-bottom border-2"
										key={approverKey}
									>
										<div className="col-12 d-flex flex-column px-0">
											<div className="fs-6">{approver.first_name} {approver.last_name} {approver?.suffix_name || ""}</div>
											<p className="pt-2">{approver.remarks}</p>
											<p className="p-1 d-flex justify-content-end">
												<span>{approver.status_at ? moment(approver.status_at).format('MMM DD, YYYY - LT') : ''}</span>
											</p>
										</div>
									</div>
								</div>
							);
						})
					}
				</div>
			</div>
		);
	}

	render() {
		return this.state.remarks.length > 0 && (
			<Col xs={12} className="pb-5">
				<Container fluid className="px-0">
					<Card className="border-2 border-gray-300 rounded-0 mb-10">
						<Card.Header className="border-2">
							<Card.Title>Remarks:</Card.Title>
						</Card.Header>
						<Card.Body>
							<div className="col-12">
								<div className="row">
									{
										this.state.remarks.map(
											(signatory, key) => this.format(signatory, key)
										)
									}
								</div>
							</div>
						</Card.Body>
					</Card>
				</Container>
			</Col>
		);
	}

}


export default WithUseCases