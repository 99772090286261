import React from "react";
import Ajax from "../../Utility/Ajax";
import { DivState, userProfilePath } from "../../Utility/Plugins";

import Breadcrumb from "../../Component/Wrapper/Header/ToolbarContent/Breadcrumbs";
import { Button, Card, Col, Dropdown, Image, Row } from "react-bootstrap";
import { BreadCrumbs, BreadCrumbsProvider } from "../../Component/Wrapper/Header/ToolbarContent/BreadcrumbsProvider";
import { logOutUser } from "../../Utility/helpers";
import { Link } from "react-router-dom";
import { PageAccessService } from "../Admin/services/users/page-access.service";

function SystemComponent({ systems, version, isAccessing }) {
	return (
		<Row className="py-5">
			{
				systems.map(
					system => (
						<Col xs={12} md={3} className="mb-6" key={system.system_page_code}>
							<Card className="border border-gray-300 border-2 rounded-1">
								<Card.Body className="pt-5 px-5 pb-0">
									<div className="d-flex justify-content-center py-1">
										<div className="w-100 h-175px d-flex justify-content-center align-items-center rounded-1">
											<img
												alt={system.title}
												src={`${Ajax.env.API_URI}/system-page/${system.system_page_code}.png?v=${version}`}
												style={{
													maxHeight: '150px',
													maxWidth: '250px',
													width: 'fit-content',
													height: 'fit-content'
												}}
												onError={
													(e) => {
														e.target.src = `${process.env.REACT_APP_API_URI}/company/company-logo.png?v=${version}`;
													}
												}
											/>
										</div>
									</div>
									<div className="w-100 h-70px d-flex align-items-center">
										<i className={`fs-1 text-primary ${system.icon} p-3`}></i>
										<span className="px-3 fs-4">
											{system.title}
										</span>
									</div>
								</Card.Body>
								<Card.Footer className="pb-5 px-5 pt-2 border-0">
									<Button
										size="sm"
										variant="primary"
										className="col-12"
										disabled={isAccessing}
										onClick={
											() => {
												window.open(system.href, "system-tabs");
											}
										}
									>
										Open in New Tab
									</Button>
								</Card.Footer>
							</Card>
						</Col>
					)
				)
			}
		</Row>
	);
}

class GoogleFormWrapper extends React.Component {
	constructor() {
		super();

		this.state = {
			user: null,
			system: null,
			version: Math.floor(Math.random() * 100),
			fetchingSystems: false,
			systems: []
		};
	}

	componentDidMount() {
		this.setState(() => {
			const hasUserStorage = localStorage.getItem("user");
			if (hasUserStorage) {

				const hasSystemStorage = localStorage.getItem("system");
				if (hasSystemStorage) {
					return {
						user: JSON.parse(hasUserStorage),
						system: JSON.parse(hasSystemStorage)
					};
				}

				return {
					user: JSON.parse(hasUserStorage),
					system: null
				};
			}

			return {
				user: null,
				system: null
			};
		}, () => {
			if (this.state.user && this.state.system) {
				this.getSystems();
			} else {
				logOutUser();
			}
		});	
	}

	render() {
		if (this.state.user && this.state.system) {
			return (
				<div className="container-fluid p-10 pt-0 bg-gray-100">
					<BreadCrumbsProvider>
						<div className="bg-gray-100 pt-10 pb-3" style={{
							position: 'sticky',
							top: 0,
							zIndex: 100
						}}>

							<Breadcrumb />
							<BreadCrumbs
								lists={[
									{ title: "Systems", href: "/system/" },
									{ title: "Google Forms", href: "/system/google-form/" },
								]}
								beside={
									<div className="d-flex">
										<Link
											to="/system/"
											className="btn btn-icon btn-active-light btn-outline btn-outline-default btn-active-icon-primary align-self-center"
											data-kt-menu-trigger="click"
											data-kt-menu-attach="parent"
											data-kt-menu-placement="bottom-end"
											data-kt-menu-flip="bottom"
											onClick={() => {
												localStorage.removeItem("system")
											}}
										>
											<i className="fs-1 fas fa-home"></i>
										</Link>
										<Dropdown>
											<Dropdown.Toggle className="px-2 py-1" as="div">
												<Image
													className="cursor-pointer"
													width={50}
													height={50}
													roundedCircle
													src={userProfilePath(this.state.user.code)}
													onError={(e) =>
														(e.target.src = `/assets/media/avatars/blank.png`)
													}
												/>
											</Dropdown.Toggle>
											<Dropdown.Menu className="py-0">
												<Dropdown.Item className="fs-4 py-5 mb-0 bg-primary text-white border border-white" style={{ borderRadius: '10px 10px 0px 0px' }}>
													<div className="d-flex flex-row-fluid align-items-center justify-content-center">
														<div>
															<div className="symbol symbol-35px cursor-pointer">
																<Image
																	className="cursor-pointer"
																	width={35}
																	height={35}
																	roundedCircle
																	src={userProfilePath(this.state.user.code)}
																	onError={(e) =>
																		(e.target.src = `/assets/media/avatars/blank.png`)
																	}
																/>
															</div>
														</div>
														<div>
															<div className="px-3 text-start text-white text-uppercase fs-8">
																<strong>
																	{this.state.user.name.first} {this.state.user.name.last} {this.state.user.name.suffix}
																</strong>
															</div>
															<div className="px-3 text-start text-white fs-9">
																{this.state.user.position.title}
															</div>
														</div>
													</div>
												</Dropdown.Item>
												{/* <Dropdown.Item
											className="fs-4"
											href="/system/user/change-password/"
										>
											Change Password
										</Dropdown.Item> */}
												<Dropdown.Divider className="my-0 py-0" />
												<Dropdown.Item className="fs-4 mb-0 py-2" href="/system/user/profile/">
													<i className="fas fa-user w-25px ms-1"></i>Account Profile
												</Dropdown.Item>
												<Dropdown.Item className="fs-4 text-danger py-2" style={{ borderRadius: '0px 0px 10px 10px' }} onClick={logOutUser}>
													<i className="fas fa-power-off w-25px ms-1 text-danger"></i>Log Out
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								}
							>
								Google Forms
							</BreadCrumbs>
						</div>
					</BreadCrumbsProvider>
					{
						this.state.fetchingSystems || this.state.systems.length <= 0 ? (
							DivState(
								this.state.fetchingSystems,
								this.state.fetchingSystems ? "GATHERING GOOGLE FORMS" : " - - NO GOOGLE FORM - - ",
								this.state.fetchingSystems ? "warning" : "danger"
							)
						) : (
							<SystemComponent
								systems={this.state.systems}
								version={this.state.version}
								isAccessing={this.state.isAccessing}
							/>
						)
						
					}
				</div>
			);
		}

		return <></>;
	}

	getSystems() {
		this.setState(
			{
				forum: null,
				fetchingSystems: true,
				systems: []
			},
			() => {
				PageAccessService.lists({
					user_code: this.state.user.code,
					system_code: this.state.system.code
				}).then(access => {
					localStorage.setItem("systems", JSON.stringify(access));

					this.setState({
						forum: access.find(
							(system) => String(system.code) === String("62cd0ddab69c6")
						),
						fetchingSystems: false,
						systems: access
					});
				}).catch(() => {
					this.setState({
						forum: null,
						fetchingSystems: false,
						systems: []
					});
				})
			}
		);
	}
}

export {
	GoogleFormWrapper
}